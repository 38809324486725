<template>
  <v-card>
    <v-dialog v-model="reportPageModal" fullscreen class="media-print">
      <PrintReport @closeModal="reportPageModal = false"
                   :flat="flat"
                   :send_by_email_button="true"
                   component_name="restructuring_agreement"
                   :item="{
                     service_id,
                     all_service,
                     contract_id,
                     date_start,
                     date_end,
                     pay_by_month,
                     debt_sum,
                     pay_table,
                     months,
                     agreement_owner,
                     date,
                     number,
                     id: itemId
                   }"
      />
    </v-dialog>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-row>
        <v-tabs color="success" class="custom-tabs">
          <v-tab>Основна інформація</v-tab>
          <v-tab>Деталі розтермінування</v-tab>

          <v-tab-item>
            <v-form v-model="formValid" ref="form">
              <v-row class="px-2 pb-2">
                <v-col cols="12" class="pb-0">
                  <v-checkbox v-model="all_service"
                              color="success"
                              label="Усі послуги"
                              hide-details class="mt-0 pt-3"
                              @change="onAllServiceChange"
                  />
                </v-col>
                <v-col cols="12" class="d-flex"  v-if="!all_service">
                  <v-select :items="services" filled label="Послуга"
                            hide-details
                            v-model="service_id"
                            :disabled="all_service"
                            :class="service_id ? '' : all_service ? '' : 'req-star'"
                            color="grey"
                            @change="onServiceChange"
                  />
                </v-col>
                <v-col cols="12" md="12" v-if="is_legacy">
                  <ACC_ContractorContract :value="contract_id" @autocompleteChange="contractChange"
                                          :person_hash="person_hash"
                                          :contractor_name="flat_name"
                                          :class="contract_id ? '' : 'req-star'" color="grey"/>
                </v-col>
                <v-col cols="12" md="6">
                  <date-component label="Місяць заборгованості" req
                                  :class_="date_start ? '' : 'req-star'"
                                  filled v-model="date_start" @afterSaveDate="afterSaveDateStart"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field type="text" hide-details filled label="Термін розтермінування (міс.)"
                                v-model.number="months"
                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                :class="months ? '' : 'req-star'"
                                color="grey"
                                v-integer
                                @change="afterChangeMonths"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field type="text" hide-details filled label="Сума боргу"
                                v-model="debt_sum"
                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                :class="debt_sum ? '' : 'req-star'"
                                color="grey"
                                v-decimal
                                @change="generatePayData"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field type="text" hide-details filled label="Місячна сума"
                                v-model="pay_by_month"
                                disabled
                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                :class="pay_by_month ? '' : 'req-star'"
                                color="grey"
                                v-decimal
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <date-component label="Дата договору"
                                  :class_="date ? '' : 'req-star'"
                                  req filled v-model="date"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field type="text" hide-details filled label="Номер договору"
                                v-model="number"
                                color="grey"/>
                </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field type="text" hide-details filled label="Власник договору"
                                  v-model="agreement_owner"
                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                  :class="agreement_owner ? '' : 'req-star'"
                                  color="grey"
                    />
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab-item>
            <div class="form-table-custom mt-3 pb-2 px-2 dense-wrapper">
              <div class="table-command-panel mb-3">
                <v-btn plain small class="grey lighten-3 mr-2" @click.stop="addRowItem">
                  <v-icon small color="success" left>mdi-plus</v-icon>
                  Додати
                </v-btn>
                <v-btn plain small class="grey lighten-3 mr-2" @click.stop="fillRows">
                  <v-icon small color="grey darken-2" left>mdi-plus</v-icon>
                  Заповнити
                </v-btn>
                <v-btn plain small class="grey lighten-3"
                       @click="onRowDelete"
                >
                  <v-icon small color="error" left>mdi-delete</v-icon>
                  Вилучити
                </v-btn>
              </div>
              <table>
                <thead>
                <tr>
                  <th style="width: 30px;"></th>
                  <th style="min-width: 30px;">№ п/п</th>
                  <th style="min-width: 180px;">Місяць</th>
                  <th style="min-width: 140px;">Сума</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, idx) in pay_table" :key="`entrance-${idx}`">
                  <td style="width: 30px">
                    <v-checkbox hide-details class="ml-2 mt-0" :ripple="false" color="secondary"
                                :value="pay_table.includes(item)"
                                @change="onRowSelect(item)"
                    />
                  </td>
                  <td style="min-width: 70px;">
                    <input type="text" v-integer disabled :value="idx + 1">
                  </td>
                  <td style="min-width: 180px;">
                    <date_input :row_num="idx" v-model="item.month" small transparent outer-style="width: 90%; padding-left: 20px;"/>
                  </td>
                  <td style="min-width: 140px;">
                    <input type="text"
                           v-decimal
                           :class="!item.sum ? 'input-error' : ''"
                           v-model.number="item.sum">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-3" v-if="!isNew" @click="reportPageModal = true">
        <v-icon color="teal">mdi-printer</v-icon>
      </v-btn>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  CREATE_RESTRUCTURING_AGREEMENT,
  UPDATE_RESTRUCTURING_AGREEMENT,
  DELETE_RESTRUCTURING_AGREEMENT
} from "@/store/actions/restructuring_agreement";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";
import {endOfMonth, round_number} from "@/utils/icons";
import {range} from 'lodash'
import {addMothsToStringDate, beginOfMonth} from "@/utils/icons"
import {mapActions, mapGetters} from "vuex";
import {SELECT_SERVICES_WITHOUT_ALL_SERVICE} from "@/store/actions/services";
import restructuringAgreementAPI from "@/utils/axios/restructuring_agreement"

const modalDeleteId = 'restructuring_agreement_modal_delete'

export default {
  name: "HWP_Modal_RestructuringAgreement",
  mixins: [ModalComponentMixin],
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input"),
    ACC_ContractorContract: () => import("@/components/accounting/autocomplite/modal/ACC_ContractorContract"),
    PrintReport: () => import("@/components/PrintReport")
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll',
      flat: "getFlat"
    }),
  },
  data() {
    return {
      person_hash: this.item.person_hash,
      flat_name: this.item.flat_name,
      service_id: this.item.service_id,
      contract_id: this.item.contract_id,
      all_service: this.item.all_service || false,
      date_start: this.item.date_start,
      date_end: this.item.date_end,
      months: this.item.months || 6,
      pay_by_month: this.item.pay_by_month || 0,
      debt_sum: this.item.debt_sum || 0,
      is_legacy: this.item.is_legacy || false,
      agreement_owner: this.item.agreement_owner,
      pay_table: [],
      pay_table_json: this.item.pay_table_json || "[]",
      date: this.item.date,
      number: this.item.number,
      selected_items: [],
      reportPageModal: false,
    }
  },
  methods: {
    ...mapActions({
      fetchServices: SELECT_SERVICES_WITHOUT_ALL_SERVICE,
    }),
    round_number,
    onAllServiceChange(payload) {
      if (payload) {
        this.service_id = null
      }

      this.$nextTick(() => {
        if (payload) {
          if (this.service_id) {
            this.debt_sum = 0
            this.pay_by_month = 0
            this.pay_table = []
          } else {
            this.getDebt()
          }
        } else {
          if (this.service_id) {
            this.getDebt()
          } else {
            this.debt_sum = 0
            this.pay_by_month = 0
            this.pay_table = []
          }
        }
      })
    },
    onServiceChange() {
      this.$nextTick(() => {
        if (this.all_service) {
          if (this.service_id) {
            this.debt_sum = 0
            this.pay_by_month = 0
            this.pay_table = []
          } else {
            this.getDebt()
          }
        } else {
          if (this.service_id) {
            this.getDebt()
          } else {
            this.debt_sum = 0
            this.pay_by_month = 0
            this.pay_table = []
          }
        }
      })
    },
    addRowItem() {
      this.pay_table.push(
          {
            month: null,
            sum: 0,
          }
      )
    },
    fillRows() {
      this.generatePayTable()
    },
    onRowDelete() {
      if (!this.selected_items.length) return
      this.selected_items.forEach(item => {
        this.pay_table.splice(this.pay_table.indexOf(item), 1)
      })
      this.selected_items = []
    },
    onRowSelect(payload) {
      if (this.selected_items.includes(payload)) {
        this.selected_items.splice(this.selected_items.indexOf(payload), 1)
      } else {
        this.selected_items.push(payload)
      }
    },
    contractChange(payload) {
      this.contract_id = payload?.value || null
    },
    afterSaveDateStart(payload) {
      if (payload) {
        if (this.months) {
          this.date_end = endOfMonth(addMothsToStringDate(beginOfMonth(this.date_start), this.months))
        }

        this.$nextTick(() => {
          this.generatePayData()
        })
      }
    },
    afterChangeMonths(payload) {
      if (payload) {
        if (this.date_start) {
          this.date_end = endOfMonth(addMothsToStringDate(beginOfMonth(this.date_start), payload + 1))
        } else {
          this.date_end = null
        }

        this.$nextTick(() => {
          this.generatePayData()
        })
      }
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.person_hash = this.item.person_hash
      this.service_id = this.item.service_id
      this.contract_id = this.item.contract_id
      this.all_service = this.item.all_service || false
      this.date_start = this.item.date_start
      this.date_end = this.item.date_end
      this.months = this.item.months || 6
      this.pay_by_month = this.item.pay_by_month || 0
      this.debt_sum = this.item.debt_sum || 0
      this.is_legacy = this.item.is_legacy || false
      this.flat_name = this.item.flat_name
      this.pay_table = []
      this.pay_table_json = this.item.pay_table_json
      this.agreement_owner = this.item.agreement_owner
      this.date = this.item.date
      this.number = this.item.number

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення договору реструктуризації',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false

        const text = `Поля: ${this.all_service ? '' : 'послуга, '}${this.is_legacy ? 'договір, ' : ''}дата початку, термін розтермінування, сума боргу та власник договору - мають бути заповнені`

        this.$store.commit(ALERT_SHOW, {
          text: text,
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        person_hash: this.person_hash,
        service_id: this.service_id,
        contract_id: this.contract_id,
        all_service: this.all_service,
        date_start: this.date_start,
        date_end: this.date_end,
        months: this.months,
        pay_by_month: this.pay_by_month,
        debt_sum: this.debt_sum,
        is_legacy: this.is_legacy,
        pay_table_json: JSON.stringify(this.pay_table),
        agreement_owner: this.agreement_owner,
        date: this.date,
        number: this.number
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_RESTRUCTURING_AGREEMENT, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_RESTRUCTURING_AGREEMENT, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    generatePayTable() {
      this.pay_table = []
      let total_sum = 0

      if (!this.months || !this.pay_by_month) {
        return
      }

      for (const month_number of range(1, this.months + 1, 1)) {
        const month = addMothsToStringDate(beginOfMonth(this.date_start), month_number)
        let month_sum = this.pay_by_month
        total_sum += month_sum

        if (month_number === this.months && total_sum !== this.debt_sum) {
          month_sum += (this.debt_sum - total_sum)
        }

        this.pay_table.push(
            {
              month,
              sum: round_number(month_sum, 2)
            }
        )
      }
    },
    generatePayData() {
      if (this.date_start && this.date_end && this.debt_sum && this.months) {
        this.pay_by_month = round_number(this.debt_sum / this.months, 2)
      }

      this.generatePayTable()
    },
    getDebt() {
      restructuringAgreementAPI.get_debt({
        person_hash: this.person_hash,
        service_id: this.all_service ? null : this.service_id
      })
          .then(response => response.data)
          .then((data) => {
            this.debt_sum = data
            this.generatePayData()
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.person_hash = payload.person_hash
              this.service_id = payload.service_id
              this.contract_id = payload.contract_id
              this.all_service = payload.all_service || false
              this.date_start = payload.date_start
              this.date_end = payload.date_end
              this.months = payload.months || 6
              this.pay_by_month = payload.pay_by_month || 0
              this.debt_sum = payload.debt_sum || 0
              this.is_legacy = payload.is_legacy || false
              this.flat_name = payload.flat_name
              this.pay_table_json = payload.pay_table_json
              this.agreement_owner = payload.agreement_owner
              this.date = payload.date
              this.number = payload.number

              if (this.isNew && this.date_start) {
                this.date_end = endOfMonth(addMothsToStringDate(beginOfMonth(this.date_start), this.months + 1))
              } else {
                this.pay_table = JSON.parse(this.pay_table_json)
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_RESTRUCTURING_AGREEMENT, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
